$('#room .avatar').owlCarousel({
    items:1,
    pagination: true,
    navText:["",""],
    responsiveClass:true,
    loop:true,
    margin:30,
    nav:true,
    //navText: ["","<img src='/templates/current/img/icon/next.png'>"],
    responsive:{
        0:{
            items:1
        },
        568:{
            items:1
        },
        768:{
            items:1
        },
        1000:{
            items:1
        }
    }
});
function slidethuml(slide1,slide2){

    var sync1 = $("."+ slide1);
    var sync2 = $("."+ slide2);
    
    var slidesPerPage = 5; //globaly define number of elements per page
    var syncedSecondary = true;
  
    sync1.owlCarousel({
      items : 1,
      slideSpeed : 2000,
      nav: true,
      autoplay: true,
      loop: true,
      responsiveRefreshRate : 200,
      navText:["",""],
    //   navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>','<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
    }).on('changed.owl.carousel', syncPosition);
  
    sync2
      .on('initialized.owl.carousel', function () {
        sync2.find(".owl-item").eq(0).addClass("current");
      })
      .owlCarousel({
      items : slidesPerPage,
      dots: false,
      nav: false,
      margin: 20,
      smartSpeed: 200,
      slideSpeed : 500,
    //   navText:["",""],
      slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
      responsive:{
        0:{
          items:2,
          slideBy: 2
        },
        540:{
          items:2,
          slideBy: 2
        },
        768:{
          items:4,
          slideBy: 4
        },
        992:{
            items:4,
            slideBy: 4
        }
    },
      responsiveRefreshRate : 100
    }).on('changed.owl.carousel', syncPosition2);
  
    function syncPosition(el) {
      //if you set loop to false, you have to restore this next line
      //var current = el.item.index;
      
      //if you disable loop you have to comment this block
      var count = el.item.count-1;
      var current = Math.round(el.item.index - (el.item.count/2) - .5);
      
      if(current < 0) {
        current = count;
      }
      if(current > count) {
        current = 0;
      }
      
      //end block
  
      sync2
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
      var onscreen = sync2.find('.owl-item.active').length - 1;
      var start = sync2.find('.owl-item.active').first().index();
      var end = sync2.find('.owl-item.active').last().index();
      
      if (current > end) {
        sync2.data('owl.carousel').to(current, 100, true);
      }
      if (current < start) {
        sync2.data('owl.carousel').to(current - onscreen, 100, true);
      }
    }
    
    function syncPosition2(el) {
      if(syncedSecondary) {
        var number = el.item.index;
        sync1.data('owl.carousel').to(number, 100, true);
      }
    }

    sync2.on("click", ".owl-item", function(e){
      e.preventDefault();
      var number = $(this).index();
      sync1.data('owl.carousel').to(number, 300, true);
    });
    
    $('.slide-main .owl-prev').click(function() {
        $('.slide-thuml .owl-prev').trigger('click');
    });
    $('.slider-left').click(function() {
        $('.slide-cafe .owl-prev').trigger('click');
    });
}
